import React from 'react';

import { useFormatMessage } from 'react-intl-hooks';
import { Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  IconChevronDown,
  IconChevronsDown,
  IconPhone,
} from '@seekube-tech/ui-kit';

import { useIsMobile } from '@/utils/MediaQueries';

import logo from '../../assets/svg/logo.svg';
import { getAppBaseUrl } from '../../utils/hostname';

const Header = () => {
  const t = useFormatMessage();
  const isMobile = useIsMobile();
  const appBaseUrl = getAppBaseUrl();

  return (
    <header className="header">
      <div className="content">
        {!isMobile && (
          <Link to="/">
            <img src={logo} alt="Seekube" />
          </Link>
        )}
        <div className="actions">
          <a href={`${appBaseUrl}/auth/signup/candidate`}>
            <Button variant="outline" color="neutral">
              {t({ id: 'header.menu.signup_candidate' })}
            </Button>
          </a>
          <Dropdown trigger={<Button variant="fill">Connexion</Button>}>
            <a href={`${appBaseUrl}/auth/login/candidate`}>
              <Dropdown.DropdownItem>
                {t({ id: 'header.menu.connect_candidate' })}
              </Dropdown.DropdownItem>
            </a>
            <Dropdown.DropdownItem>
              <a href={`${appBaseUrl}/auth/login/recruiter`}>
                {t({ id: 'header.menu.connect_recruiter' })}
              </a>
            </Dropdown.DropdownItem>
            <Dropdown.DropdownItem>
              <a href={`${appBaseUrl}/auth/login/owner`}>
                {t({ id: 'header.menu.connect_owner' })}
              </a>
            </Dropdown.DropdownItem>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
