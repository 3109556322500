import React, { useEffect, useState } from 'react';

import { array, func, object, string } from 'prop-types';

import { useGetDefaultCriteria } from '@/queries/criterion/useGetDefaultCriteria';
import { useIsMobile } from '@/utils/MediaQueries';
import { algoliaSearch } from '@/utils/algolia';
import { CRITERIA } from '@/utils/constants';

import { Desktop } from './Desktop/Desktop';
import { Mobile } from './Mobile/Mobile';
import {
  choiceToSelectOption,
  getAlgoliaHitsChoices,
  getSelectedContracts,
  getSelectedLocalisations,
  sortOptions,
} from './utils';

export const SearchEngine = ({
  facets,
  keywords,
  contracts,
  localisations,
  onSubmit,
  onFilterChange,
}) => {
  const isWiderThanMobile = !useIsMobile();
  const [contractsSelected, setContractsSelected] = useState([]);
  const [localisationSelected, setLocalisationSelected] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [localisationOptions, setLocalisationOptions] = useState([]);
  const [searchHits, setSearchHits] = useState();
  const criteria = useGetDefaultCriteria();
  const [selectedSearch, setSelectedSearch] = useState();
  const [inputSearch, setInputSearch] = useState(keywords);

  useEffect(() => {
    algoliaSearch(inputSearch, hits => setSearchHits(getAlgoliaHitsChoices(hits)));
  }, []);

  useEffect(() => {
    if (criteria.data && facets) {
      const contractOptions =
        criteria.data?.find(criteria => criteria.key === 'CONTRACT')?._choices || [];

      const localisationOptions =
        criteria.data?.find(criteria => criteria.key === 'MOBILITY')?._choices || [];

      setContractOptions(
        sortOptions(choiceToSelectOption(facets, contractOptions, 'CONTRACT'), contractsSelected),
      );
      setLocalisationOptions(
        sortOptions(
          choiceToSelectOption(facets, localisationOptions, 'MOBILITY'),
          localisationSelected,
        ),
      );

      setContractsSelected(getSelectedContracts(contractOptions, contracts));
      setLocalisationSelected(getSelectedLocalisations(localisationOptions, localisations));
    }
  }, [criteria.data, facets]);

  const handleSearchEvents = () => {
    onSubmit?.({
      contracts: contractsSelected,
      localisations: localisationSelected,
      keywords: inputSearch,
    });
  };

  const handleLocalisationsSelected = localisations => {
    setLocalisationSelected(localisations);
    onFilterChange(
      {
        keywords: inputSearch || '',
        contracts,
        localisations,
      },
      CRITERIA.MOBILITY,
    );
  };

  const handleContractsSelected = contracts => {
    setContractsSelected(contracts);
    onFilterChange(
      {
        keywords: inputSearch || '',
        contracts,
        localisations,
      },
      CRITERIA.CONTRACT,
    );
  };

  const handleSelectedSearch = search => {
    const newSearch = searchHits.find(hit => search === hit.value);
    setSelectedSearch(newSearch?.label || search);

    onFilterChange?.({
      keywords: newSearch?.label ?? search,
      contracts,
      localisations,
    });
  };

  const handleSearch = search => {
    setInputSearch(search);
    algoliaSearch(search, hits => setSearchHits(getAlgoliaHitsChoices(hits)));
  };

  if (isWiderThanMobile) {
    return (
      <Desktop
        selectedSearch={selectedSearch}
        setSelectedSearch={handleSelectedSearch}
        inputSearch={inputSearch}
        setInputSearch={handleSearch}
        searchHits={searchHits}
        contractsSelected={contractsSelected}
        setContractsSelected={handleContractsSelected}
        contractOptions={contractOptions}
        localisationSelected={localisationSelected}
        setLocalisationSelected={handleLocalisationsSelected}
        localisationOptions={localisationOptions}
        handleSearchEvents={() =>
          handleSearchEvents({ contracts, localisations, keywords: inputSearch })
        }
      />
    );
  }

  return (
    <Mobile
      selectedSearch={selectedSearch}
      setSelectedSearch={handleSelectedSearch}
      inputSearch={inputSearch}
      setInputSearch={handleSearch}
      searchHits={searchHits}
      contractsSelected={contractsSelected}
      setContractsSelected={handleContractsSelected}
      contractOptions={contractOptions}
      localisationSelected={localisationSelected}
      setLocalisationSelected={handleLocalisationsSelected}
      localisationOptions={localisationOptions}
      handleSearchEvents={() =>
        handleSearchEvents({ contracts, localisations, keywords: inputSearch })
      }
    />
  );
};

SearchEngine.propTypes = {
  keywords: string,
  contracts: array,
  localisations: array,
  onSubmit: func,
  facets: object,
  onFilterChange: func,
};
