import React, { useEffect, useState } from 'react';

import { IconX } from '@seekube-tech/ui-kit';

import Button from '../Button';
// Create a CSS file for styling
import styles from './styles.less';

const Modal = ({ children, triggerText, triggerClass }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (event.target.classList.contains('modal')) {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div>
      <Button className={triggerClass} onClick={openModal} variant="tonal">
        {triggerText}
      </Button>

      {isOpen && (
        <div className="modal">
          <div className="modalContent">
            <div className={styles.close} onClick={closeModal}>
              <IconX />
            </div>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
